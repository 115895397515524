@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


html,
body {
  height: 100%;
  font-family: "Inter", serif;
  background-color: #000;
  color: #fff;
}

.banner-bg::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url('../public/assets/images/banner-graphics.png')top center;
  background-repeat: no-repeat;
  z-index: -1;
  background-attachment: fixed;
}

.shadow-light {
  filter: drop-shadow(0 0 30px rgba(255,255,255, 0.6));
}
.shadow-red {
  filter: drop-shadow(0 0 30px rgba(187,32,37, 0.9));
}
.banner-bg::after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  height: 400px;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  z-index: 1;
}
.contact-cta::after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  height: 200px;
  width: 100%;
  background: linear-gradient(180deg, rgba(37, 6, 7, 0) 0%, #250607 100%);
  z-index: 1;
  border-radius: 0 0 16px 16px;
}
.about-sec::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(11, 11, 11, 0.00) 68.56%, #0B0B0B 99.99%);
  z-index: -1;
}
.about-sec::before,
.dashboard-sec::after  {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(261deg, rgba(177, 9, 9, 0) 36.37%, rgba(11, 11, 11, 0.63) 47.31%, #0B0B0B 62.77%, #0B0B0B 89.84%);
  z-index: -1;
}
.gradient-border-red {
  border-image: radial-gradient( red, rgba(0, 0, 0, 0)) 1 100%;
}
.gradient-border-light {
  border-image: radial-gradient( white, rgba(0, 0, 0, 0)) 1 100%;
}
.gradient-border-custom {
  border-style: solid;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-image-slice: 1;
  border-width: 1px;
}
.gradient-border-custom-top {
  border-style: solid;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  border-image-slice: 1;
  border-width: 1px;
}
/* Only for borders */
.border-gray-custom {
  border-image-source: linear-gradient(to right, transparent, transparent);
  background-clip: padding-box;
  position: relative;
}
.border-gray-custom::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  border-radius: inherit;
  background: linear-gradient(to right, #181818, #313131);
}
.cta-sec::after {
  position: absolute;
  content: '';
  top: 0;
  left: 20%;
  height: 100%;
  width: 100%;
  background-image: radial-gradient(rgba(255,255,255,.125) 1%, rgba(11, 11, 11, 0) 65%);
  z-index: -1;
}
.contact-sec::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0%;
  height: 100%;
  width: 100%;
  background-image: radial-gradient(rgba(255,255,255,.125) 1%, rgba(11, 11, 11, 0) 75%);
  z-index: -1;
}

.roboto-mono {
  font-family: "Roboto Mono", monospace;
}

.mySwiper.fuelCardSlider {
  max-height: calc(100vh - 5rem);
  /* overflow: hidden; */
}
.fuelCardSlider .swiper-pagination-bullet {
  height: 80px;
  width: 2px;
  border-radius: 0px;
  background-color: #b9b7b7;
}
.fuelCardSlider .swiper-pagination-bullet-active {
  background-color: #4E46B4;
}

.clientReview .swiper-slide-active .active-brown  {
  background-color: #250607;
}
.clientReview .swiper-slide-next,
.clientReview .swiper-slide-prev {
  /* opacity: .35; */
  /* transform: scale(.9) !important; */
}
.clientReview .swiper-button-prev:after,
.clientReview .swiper-button-next:after {
  font-size: inherit;
}
.clientReview .swiper-button-prev, 
.clientReview .swiper-button-next {
  color: #BB2025;
  height: 30px;
  width: 30px;
  background-color: #000;
  border-radius: 15px;
  padding: .25rem;
  font-size: 16px;
  border: 1px solid #BB2025;
  top: calc(100% - 1.5rem);
}
.clientReview .swiper-button-prev:hover,
.clientReview .swiper-button-next:hover{
  border: 1px solid #666;
  color: #666;
}
.clientReview .swiper-button-next {
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.clientReview .swiper-button-prev {
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.clientReview {
  height: 100%;
  min-height: 500px;
}
.clientReview .swiper-pagination-bullet {
  border: 1px solid red;
  opacity: 1;
}
.clientReview .swiper-pagination-bullet-active {
  opacity: 1;
  background: #BB2025;
}
@media (min-width:769px) {
  .clientReview .swiper-pagination {
    /* display: none; */
  }
}
@media (max-width:768px) {
  .clientReview .swiper-button-prev, .clientReview .swiper-button-next {
    display: none;
  }
  .clientReview .swiper-pagination {
    display: none;
  }
}
@media (max-width:767px) {
  .mySwiper.fuelCardSlider {
    max-height: calc(60vh - 5rem);
  }
  .fuelCardSlider .swiper-pagination-bullet {
    height: 20px;
  }
}
.footer-links ul li a,
.footer-links ul li {
  font-size: 13px;
}
.footer-links ul li a:hover {
  color: #FF4E64;
  transition: all ease .3s;
}
.custom-list li{
  position: relative;
  padding-left: 20px;
  margin-bottom: 5px;
}
.custom-list li::before {
  content: '';
  position: absolute;
  height: 5px;
  width: 5px;
  background-color: #fff;
  border-radius: 50%;
  left: 0;
  top: 8px;
}
textarea:focus {
  border: 1px solid #444;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Navbar.css */
.navbar {
  width: 100%;
  /* background-color: #000; */
  color: white;
  padding: 10px 15px;
  transition: top 0.3s;
  border-radius: 10px;
  position: fixed; /* Initial position */
  /* border-bottom: 1px solid transparent; */
}

.navbar {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  z-index: 999;
  /* background-color: rgba(21, 21, 21, 0.75); */
  /* backdrop-filter: saturate(180%) blur(20px); */
  transition: all 0.3s ease-in-out;
  /* border-bottom: 1px solid rgba(50, 50, 50, 0.8); */
}
.background-blur-custom {
  background-color: rgba(21, 21, 21, 0.7);
  backdrop-filter: saturate(180%) blur(20px);
}


/* .footer-sec::after{
  content: '';
  position: absolute;
  right: 0px;
  top: -30px;
  background: url(../src/assets/images/logo-behind-right.png) no-repeat;
  height: 300px;
  width: 95px;
  z-index: -1;
} */
.footer-sec .dashboard-inner-wrap {
  background-color: rgba(21, 21, 21, 0.5);
}

.contact-inner-sec::after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 60px;
  width: 80%;
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(187,32,37,1) 50%, rgba(0,0,0,1) 100%);
  z-index: 1;
}
.sticky-layer {
  position: sticky;
  top: 0;
  height: 100vh; /* Full viewport height for each layer */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
}

.panel {
  position: relative;
  transition: background 0.3s ease-in-out;
}
.active-panel {
  /* background: #f0f8ff; */
}
.icon-overlay {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  font-size: 1rem;
  z-index: 10;
}

.video-sec iframe {
  aspect-ratio:16/9;
} 

.comingsoon-sec::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url('../public/assets/images/banner-graphics.png')top center;
  background-repeat: no-repeat;
  z-index: -1;
  background-attachment: fixed;
}

[type='text']:focus, 
[type='email']:focus, 
[type='url']:focus,
 [type='password']:focus, 
 [type='number']:focus, 
 [type='date']:focus, 
 [type='datetime-local']:focus, 
 [type='month']:focus, 
 [type='search']:focus, 
 [type='tel']:focus, 
 [type='time']:focus, 
 [type='week']:focus, 
 [multiple]:focus, textarea:focus, select:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: none;
    border: 1px solid #444;
}
@media (max-width:1200px) {
  .navbar {
    max-width: calc(100vw - 2rem) !important;
  }
}
@media (max-width:991px) {
  .only-gas-sec::after {
    left: 0;
  }
  
  .pin-spacer,
   .panel {
    display: none !important;
   }
   ::placeholder {
    font-size: .875rem;
   }
}
@media (max-width:475px) {
  .navbar {
    /* height: 48px; */
    padding: 8px 20px;
  }
  .pin-spacer {
   display: none !important;
  }
  /* br {
    display: none;
  } */
   .only-gas-sec br {
    display: none;;
   }
   ::placeholder {
    font-size: .875rem;
   }
   .recaptcha iframe {
    /* width: calc(100vw - 5rem) !important; */
   }
   .pin-spacer,
   .panel {
    display: none !important;
   }
   .rc-anchor-normal .rc-anchor-content,
   .rc-anchor-normal .rc-anchor-checkbox-label {
      width: auto;
   }
   #recaptcha_area #recaptcha_response_field {
    position: static !important;
  }
  .banner-bg::after {
    height: 200px;
  }
}